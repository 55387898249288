<template>
  <div>
    <my-header></my-header>
    <div class="table-box">
      <div>
        <span>已提交业务详情</span>
      </div>
      <div class="detail">
        <div>
          <span>标题:</span>
          <span
            v-text="
              detail.title != '' && detail.title != null
                ? detail.title
                : '没有留下标题'
            "
          ></span>
        </div>
        <div>
          <span>项目联系人:</span>
          <span
            v-text="
              detail.projectContact != '' && detail.projectContact != null
                ? detail.projectContact
                : '没有留下姓名'
            "
          ></span>
        </div>
        <div>
          <span>项目联系人电话:</span>
          <span
            v-text="
              detail.projectContactPhone != '' &&
              detail.projectContactPhone != null
                ? detail.projectContactPhone
                : '没有留下电话'
            "
          ></span>
        </div>
        <div>
          <span>地区:</span>
          <span
            v-if="detail.region"
            v-text="`${detail.region[0]}${detail.region[1]}${detail.region[2]}`"
          ></span>
          <span v-else> 没有留下地区信息 </span>
        </div>
        <div>
          <span>采购单位:</span>
          <span
            v-text="
              detail.purchasingUnit != ''
                ? detail.purchasingUnit
                : '没有项目联系人'
            "
          ></span>
        </div>
        <!-- <div>
          <span>项目联系电话:</span>
          <span
            v-text="
              detail.projectContactPhone != ''
                ? detail.projectContactPhone
                : '没有项目联系人电话'
            "
          ></span>
        </div> -->
        <div>
          <span>预计最佳开始跟踪时间:</span>
          <span
            v-text="
              detail.startTrackingTime != ''
                ? detail.startTrackingTime
                : '没有预计最佳开始跟踪时间'
            "
          ></span>
        </div>
        <div>
          <span>预计需求时间:</span>
          <span
            v-text="
              detail.demandTime != '' ? detail.demandTime : '没有预计需求时间'
            "
          ></span>
        </div>
        <div>
          <span>采购类型:</span>
          <span>{{ detail.purchaseType | purchaseType  }}</span>
        </div>
        <div>
          <span>业务类型:</span>
          <span>{{ detail.type | type }}</span>
        </div>
        <div>
          <span>预算:</span>
          <span
            v-text="detail.budget != '' ? detail.budget : '没有填写预算'"
          ></span>
        </div>
        <div>
          <span>业务所在地址:</span>
          <span
            v-text="
              detail.address != '' ? detail.address : '没有填写业务所在地址'
            "
          ></span>
        </div>
        <div>
          <span>业务内容:</span>
          <span v-text="detail.businessContent"></span>
        </div>
        <div class="file">
          <div>备注附件:</div>
          <div>
            <div class="downLoadUrl" v-for="(v, k) of detail.message" :key="k">
              <p @click="downLoadUrl(v.url, v.name)">点击下载{{ v.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/my-header.vue";

export default {
  data() {
    return {
      detail: {},
    };
  },
  filters: {
    type(val) {
      if (val == 0) {
        return "单人";
      } else if (val == 1) {
        return "多人";
      }else{
        return '不详';
      }
    },
    purchaseType(val) {
      if (val == 1) {
        return "厨房设备采购";
      } else if (val == 2) {
        return "厨房服务采购";
      } else if (val == 3) {
        return "食材采购";
      } else if (val == 4) {
        return "私有业务";
      }
    },
  },
  methods: {
    getItem() {
      this.detail = JSON.parse(this.$route.query.item);
      this.detail.message = JSON.parse(this.detail.message);
      console.log(this.detail);
    },
    // 点击下载文件
    downLoadUrl(url, name) {
      const a = document.createElement("a");
      // 这里是将url转成blob地址，
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob);
          a.download = name; // 下载文件的名字
          document.body.appendChild(a);
          a.click();
        });
    },
  },
  created() {
    this.getItem();
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  margin-top: 140px;
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
  }
  .detail {
    width: 100%;
    color: #666;
    border: 1px solid #ccc;
    div {
      padding: 5px;
    }
    div:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
    div > span:nth-child(1) {
      display: inline-block;
      text-align: right;
      width: 50%;
    }
    div > span:nth-child(2) {
      margin-left: 15px;
    }
    .file {
      text-align: center;
      .downLoadUrl {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
</style>