var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('my-header'),_c('div',{staticClass:"table-box"},[_vm._m(0),_c('div',{staticClass:"detail"},[_c('div',[_c('span',[_vm._v("标题:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.title != '' && _vm.detail.title != null
              ? _vm.detail.title
              : '没有留下标题'
          )}})]),_c('div',[_c('span',[_vm._v("项目联系人:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.projectContact != '' && _vm.detail.projectContact != null
              ? _vm.detail.projectContact
              : '没有留下姓名'
          )}})]),_c('div',[_c('span',[_vm._v("项目联系人电话:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.projectContactPhone != '' &&
            _vm.detail.projectContactPhone != null
              ? _vm.detail.projectContactPhone
              : '没有留下电话'
          )}})]),_c('div',[_c('span',[_vm._v("地区:")]),(_vm.detail.region)?_c('span',{domProps:{"textContent":_vm._s(("" + (_vm.detail.region[0]) + (_vm.detail.region[1]) + (_vm.detail.region[2])))}}):_c('span',[_vm._v(" 没有留下地区信息 ")])]),_c('div',[_c('span',[_vm._v("采购单位:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.purchasingUnit != ''
              ? _vm.detail.purchasingUnit
              : '没有项目联系人'
          )}})]),_c('div',[_c('span',[_vm._v("预计最佳开始跟踪时间:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.startTrackingTime != ''
              ? _vm.detail.startTrackingTime
              : '没有预计最佳开始跟踪时间'
          )}})]),_c('div',[_c('span',[_vm._v("预计需求时间:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.demandTime != '' ? _vm.detail.demandTime : '没有预计需求时间'
          )}})]),_c('div',[_c('span',[_vm._v("采购类型:")]),_c('span',[_vm._v(_vm._s(_vm._f("purchaseType")(_vm.detail.purchaseType)))])]),_c('div',[_c('span',[_vm._v("业务类型:")]),_c('span',[_vm._v(_vm._s(_vm._f("type")(_vm.detail.type)))])]),_c('div',[_c('span',[_vm._v("预算:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.detail.budget != '' ? _vm.detail.budget : '没有填写预算')}})]),_c('div',[_c('span',[_vm._v("业务所在地址:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.address != '' ? _vm.detail.address : '没有填写业务所在地址'
          )}})]),_c('div',[_c('span',[_vm._v("业务内容:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.detail.businessContent)}})]),_c('div',{staticClass:"file"},[_c('div',[_vm._v("备注附件:")]),_c('div',_vm._l((_vm.detail.message),function(v,k){return _c('div',{key:k,staticClass:"downLoadUrl"},[_c('p',{on:{"click":function($event){return _vm.downLoadUrl(v.url, v.name)}}},[_vm._v("点击下载"+_vm._s(v.name))])])}),0)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("已提交业务详情")])])}]

export { render, staticRenderFns }